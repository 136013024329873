
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useForm, ValidationError } from '@formspree/react'

function RegisterPage({ location, data }) {

  const [state, handleSubmit] = useForm("mvolwjvz") // Formspree form ID

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  const courses = data.allCoursesYaml.nodes

  return (
    <Layout location={location}>
      <Seo title="Register" />

      {/* TODO: pre-fill form based on query parameters */}  

      {/* https://www.kindacode.com/article/react-typescript-handling-onclick-event/ */}
      {/* https://www.gatsbyjs.com/docs/building-a-contact-form/ */}    
      {/* https://formspree.io/library/registration-form/#editing-section */}

      <section className="pb-20 bg-gray-100 fullbleed mt-60 md:mt-40 lg:pb-34">
        <div className="container mt-48 md:grid md:grid-cols-2 md:pl-0">
          <div className="col-start-1 col-span-2 row-start-1 pt-64 md:mt-16 md:pl-12 md:pt-0 lg:mt-24 lg:pl-23.5">
            <h2 className="text-center">Workshop Registration/Request</h2>
            <form className="mx-auto mt-9 max-w-111" onSubmit={handleSubmit}>
              <div className="grid gap-3.5">
                <label className="block">
                  <span className="sr-only">Name</span>
                  <input type="text" className="block w-full px-6 py-4 border-transparent rounded text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Name" />
                  <ValidationError prefix="Email" field="email" errors={state.errors}
                />
                </label>
                <label className="block">
                  <span className="sr-only">Email</span>
                  <input type="email" className="block w-full px-6 py-4 border-transparent rounded text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Email" />
                </label>
                <label className="block">
                  <span className="sr-only">Phone Number</span>
                  <input type="tel" className="block w-full px-6 py-4 border-transparent rounded text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Phone Number" />
                </label>
                <label className="block">
                  <span className="sr-only">Your Question</span>
                  <textarea className="block w-full px-6 py-4 border-transparent rounded min-h-40 text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Your Question"></textarea>
                </label>
              </div>
              <button className="w-full btn-primary mt-9" disabled={state.submitting}>Send Question</button>
            </form>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default RegisterPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allCoursesYaml(
      sort: { fields: [priority], order: ASC }
      filter: { hidden: { ne: true } }
    ) {
      nodes {
        id
        title
        abstract
        description
        difficulty
        duration
        icon
        slug
        type
        top
        meta {
          keywords
          title
          description
        }
      }
    }
  }
`
